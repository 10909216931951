import React from 'react'
import { NpmInstitutionalPage } from 'src/local_modules'

import useCmsInstitutional from '../../hooks/useCmsInstitutional'
import { AttendanceMock } from '../../mocks/AttendanceMock'

import '../../styles/pages/atendimento.scss'

const Attendance = () => {
  const { attendanceBanners, attendanceContent } = useCmsInstitutional()

  return (
    <NpmInstitutionalPage
      title="Área de Atendimento"
      banners={attendanceBanners}
    >
      {attendanceContent ? (
        <div
          className="attendance-container"
          dangerouslySetInnerHTML={{
            __html: attendanceContent,
          }}
        />
      ) : (
        <AttendanceMock />
      )}
    </NpmInstitutionalPage>
  )
}

export default Attendance
